<script setup lang="ts">
import type { IDashboardResultExpiringAndHeldOver } from '~/composables/useAPI/useApiDashboard'
import { createDashboardReportURI, DashboardQuery } from '@dashboard'

const companyId = getCurrentDivisionId()

const { data, isPending } = useApiDashboard(
  companyId,
  DashboardQuery.EXPIRING_AND_HELD_OVER,
)
const expiringAndHeldOver = computed(
  () => data.value?.results as IDashboardResultExpiringAndHeldOver | undefined,
)
</script>

<template>
  <DashboardCards>
    <DashboardCard
      :count="expiringAndHeldOver?.lessThanSixMonths.count ?? 0"
      badge="0–6 months"
      text="Leases Expiring"
      button-text="Open"
      :to="
        createDashboardReportURI(
          companyId,
          expiringAndHeldOver?.lessThanSixMonths.link,
          data?.templateId,
        )
      "
      :loading="isPending"
      :variant="
        expiringAndHeldOver?.lessThanSixMonths?.count &&
        expiringAndHeldOver?.lessThanSixMonths?.count > 0
          ? 'primary'
          : 'secondary'
      "
    />
    <DashboardCard
      :count="expiringAndHeldOver?.sixMonthsToAYear.count ?? 0"
      badge="6–12 months"
      text="Leases Expiring"
      variant="secondary"
      button-text="Open"
      :to="
        createDashboardReportURI(
          companyId,
          expiringAndHeldOver?.sixMonthsToAYear.link,
          data?.templateId,
        )
      "
      :loading="isPending"
    />
    <DashboardCard
      :count="expiringAndHeldOver?.oneToTwoYears.count ?? 0"
      badge="12–24 months"
      text="Leases Expiring"
      variant="secondary"
      button-text="Open"
      :to="
        createDashboardReportURI(
          companyId,
          expiringAndHeldOver?.oneToTwoYears.link,
          data?.templateId,
        )
      "
      :loading="isPending"
    />
    <DashboardCard
      :count="expiringAndHeldOver?.heldOver.count ?? 0"
      text="Leases on Holdover"
      variant="secondary"
      button-text="Open"
      :to="
        createDashboardReportURI(
          companyId,
          expiringAndHeldOver?.heldOver.link,
          data?.templateId,
        )
      "
      :loading="isPending"
    />
  </DashboardCards>
</template>
